import React from "react"
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const LoginSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-3 pb-5"
      style={{
        background: "linear-gradient(#E5E8e9 30%, #EFF2F4 70%)",
        color: "#365c70",
      }}
    >

      <div className="container pl-5 pr-5" style={{width:"500px"}}>
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0 mt-5"
          style={{ fontSize: "24px", color: "#365c70" }}
        >
          Partner Login
        </div>

        <form>
          <div className="form-outline mb-4 mt-2">
            <input type="email" id="form2Example1" className="form-control" placeholder="User Name" />
          </div>

          <div className="form-outline mb-4">
            <input type="password" id="form2Example2" className="form-control" placeholder="Passwords"/>
          </div>

          <a href="/account-failed/">
            <button type="button" className="btn btn-primary btn-block mb-4" >Login</button>
          </a>
        </form>
      </div>
    </section>
  )
}
export default LoginSection